// extracted by mini-css-extract-plugin
export var accent = "ClientList-module--accent--f30ab";
export var bg = "ClientList-module--bg--4d10c";
export var button = "ClientList-module--button--1a394";
export var cardIntersect = "ClientList-module--card-intersect--4631d";
export var client = "ClientList-module--client--f7c79";
export var content = "ClientList-module--content--7e914";
export var header = "ClientList-module--header--2a429";
export var imgContainer = "ClientList-module--img-container--9da5f";
export var imgInner = "ClientList-module--img-inner--fc4ef";
export var imgPlaceholder = "ClientList-module--img-placeholder--84ae7";
export var item = "ClientList-module--item--f88f8";
export var itemWrapper = "ClientList-module--item-wrapper--e640d";
export var list = "ClientList-module--list--6d862";
export var logoImg = "ClientList-module--logo-img--5c1b6";
export var tabletImg = "ClientList-module--tablet-img--050d7";
export var wrapper = "ClientList-module--wrapper--e9b65";