// extracted by mini-css-extract-plugin
export var btn = "Closing-module--btn--b81b1";
export var btnMobile = "Closing-module--btn-mobile--5a2f3";
export var container = "Closing-module--container--91ad3";
export var content = "Closing-module--content--fabd1";
export var devices = "Closing-module--devices--670f8";
export var devicesPlaceholder = "Closing-module--devices-placeholder--446d2";
export var imgContainer = "Closing-module--img-container--5ea20";
export var laptop = "Closing-module--laptop--af1e1";
export var logo = "Closing-module--logo--9dbb0";
export var logoTablet = "Closing-module--logo-tablet--d47e2";
export var model = "Closing-module--model--a2733";
export var modelDesktop = "Closing-module--model-desktop--b6585";
export var phone = "Closing-module--phone--70401";
export var testimonial = "Closing-module--testimonial--9abd8";
export var wrapper = "Closing-module--wrapper--08e94";