import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import * as cx from './Closing.module.scss'

const Closing = ({
  wrapperClassName,
  bg,
  heading,
  testimonial,
  client,
  domain,
  buttonText,
  buttonLink,
  alt,
  logoImg,
  laptopImg,
  phoneImg,
  modelImg,
  headingClassName = 'subtitle-1',
  testimonialClassName = 'subtitle-9',
  clientClassName = 'subtitle-3',
  domainClassName = 'menu-link',
}) => {
  return (
    <section
      className={clsx(wrapperClassName, [cx.wrapper])}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={cx.container}>
        <h4 className={headingClassName}>{heading}</h4>

        <div className={cx.content}>
          <Button large wrapperClassName={cx.btnMobile} text={buttonText} />
          <div className={cx.imgContainer}>
            <ElementImage className={cx.model} src={modelImg} alt={client} />
            <ElementImage
              className={cx.logo}
              src={logoImg}
              alt={`${alt} logo`}
            />
            <div className={cx.devices}>
              <div className={cx.devicesPlaceholder} />
              <ElementImage
                className={cx.laptop}
                src={laptopImg}
                alt={`${alt} laptop`}
              />
              <ElementImage
                className={cx.phone}
                src={phoneImg}
                alt={`${alt} phone`}
              />
            </div>
          </div>

          <div className={cx.modelDesktop}>
            <ElementImage src={modelImg} alt={client} />
          </div>
          <div className={cx.testimonial}>
            <ElementImage
              className={cx.logoTablet}
              src={logoImg}
              alt={`${alt} logo`}
            />
            <p className={testimonialClassName}>{parse(testimonial)}</p>
            <span className={clientClassName}>{client}</span>
            <span className={domainClassName}>{domain}</span>
            <Link to={buttonLink}>
              <Button large wrapperClassName={cx.btn} text={buttonText} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

Closing.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  heading: PropTypes.string,
  testimonial: PropTypes.string,
  client: PropTypes.string,
  domain: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  alt: PropTypes.string,
  logoImg: PropTypes.string,
  laptopImg: PropTypes.string,
  phoneImg: PropTypes.string,
  modelImg: PropTypes.string,
  headingClassName: PropTypes.string,
  testimonialClassName: PropTypes.string,
  clientClassName: PropTypes.string,
  domainClassName: PropTypes.string,
}

export default Closing
