import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'
import {
  Banner,
  ClientList,
  Closing,
} from '@components/pages/video-testimonials'

import {
  pageData as page,
  bannerData,
  clientListData,
  closingData,
} from '@src/data/VideoTestimonials'
import useVideoTestimonialsMetadata from '@hooks/video-testimonials-metadata'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './VideoTestimonials.module.scss'

const VideoTestimonials = () => {
  const {
    bannerBG,
    bannerImg,
    clientListBG,
    clientListAccent,
    clientListChooClient,
    clientListChooLogo,
    clientListChooTablet,
    clientListPrattClient,
    clientListPrattLogo,
    clientListPrattTablet,
    clientListNarteyClient,
    clientListNarteyLogo,
    clientListNarteyTablet,
    clientListJacobsonClient,
    clientListJacobsonLogo,
    clientListJacobsonTablet,
    clientListHabibiClient,
    clientListHabibiLogo,
    clientListHabibiTablet,
    clientListTutorClient,
    clientListTutorLogo,
    clientListTutorTablet,
    clientListGilletteClient,
    clientListGilletteLogo,
    clientListGilletteTablet,
    clientListDollClient,
    clientListDollLogo,
    clientListDollTablet,
    closingModel,
    closingLogo,
    closingLaptop,
    closingPhone,
  } = useVideoTestimonialsMetadata()
  const clientItemData = [
    {
      client: clientListData.clientList?.clientItem[0]?.client,
      domain: clientListData.clientList?.clientItem[0]?.domain,
      testimonial1: clientListData.clientList?.clientItem[0]?.testimonials[0],
      testimonial2: clientListData.clientList?.clientItem[0]?.testimonials[1],
      link: clientListData.clientList?.clientItem[0]?.link,
      clientImg: extractImage(clientListChooClient),
      logoImg: extractImage(clientListChooLogo),
      tabletImg: extractImage(clientListChooTablet),
    },
    {
      client: clientListData.clientList?.clientItem[1]?.client,
      domain: clientListData.clientList?.clientItem[1]?.domain,
      testimonial1: clientListData.clientList?.clientItem[1]?.testimonials[0],
      testimonial2: clientListData.clientList?.clientItem[1]?.testimonials[1],
      link: clientListData.clientList?.clientItem[1]?.link,
      clientImg: extractImage(clientListPrattClient),
      logoImg: extractImage(clientListPrattLogo),
      tabletImg: extractImage(clientListPrattTablet),
    },
    {
      client: clientListData.clientList?.clientItem[2]?.client,
      domain: clientListData.clientList?.clientItem[2]?.domain,
      testimonial1: clientListData.clientList?.clientItem[2]?.testimonials[0],
      testimonial2: clientListData.clientList?.clientItem[2]?.testimonials[1],
      link: clientListData.clientList?.clientItem[2]?.link,
      clientImg: extractImage(clientListNarteyClient),
      logoImg: extractImage(clientListNarteyLogo),
      tabletImg: extractImage(clientListNarteyTablet),
    },
    {
      client: clientListData.clientList?.clientItem[3]?.client,
      domain: clientListData.clientList?.clientItem[3]?.domain,
      testimonial1: clientListData.clientList?.clientItem[3]?.testimonials[0],
      testimonial2: clientListData.clientList?.clientItem[3]?.testimonials[1],
      link: clientListData.clientList?.clientItem[3]?.link,
      clientImg: extractImage(clientListJacobsonClient),
      logoImg: extractImage(clientListJacobsonLogo),
      tabletImg: extractImage(clientListJacobsonTablet),
    },
    {
      client: clientListData.clientList?.clientItem[4]?.client,
      domain: clientListData.clientList?.clientItem[4]?.domain,
      testimonial1: clientListData.clientList?.clientItem[4]?.testimonials[0],
      testimonial2: clientListData.clientList?.clientItem[4]?.testimonials[1],
      link: clientListData.clientList?.clientItem[4]?.link,
      clientImg: extractImage(clientListHabibiClient),
      logoImg: extractImage(clientListHabibiLogo),
      tabletImg: extractImage(clientListHabibiTablet),
    },
    {
      client: clientListData.clientList?.clientItem[5]?.client,
      domain: clientListData.clientList?.clientItem[5]?.domain,
      testimonial1: clientListData.clientList?.clientItem[5]?.testimonials[0],
      testimonial2: clientListData.clientList?.clientItem[5]?.testimonials[1],
      link: clientListData.clientList?.clientItem[5]?.link,
      clientImg: extractImage(clientListTutorClient),
      logoImg: extractImage(clientListTutorLogo),
      tabletImg: extractImage(clientListTutorTablet),
    },
    {
      client: clientListData.clientList?.clientItem[6]?.client,
      domain: clientListData.clientList?.clientItem[6]?.domain,
      testimonial1: clientListData.clientList?.clientItem[6]?.testimonials[0],
      testimonial2: clientListData.clientList?.clientItem[6]?.testimonials[1],
      link: clientListData.clientList?.clientItem[6]?.link,
      clientImg: extractImage(clientListGilletteClient),
      logoImg: extractImage(clientListGilletteLogo),
      tabletImg: extractImage(clientListGilletteTablet),
    },
    {
      client: clientListData.clientList?.clientItem[7]?.client,
      domain: clientListData.clientList?.clientItem[7]?.domain,
      testimonial1: clientListData.clientList?.clientItem[7]?.testimonials[0],
      testimonial2: clientListData.clientList?.clientItem[7]?.testimonials[1],
      link: clientListData.clientList?.clientItem[7]?.link,
      clientImg: extractImage(clientListDollClient),
      logoImg: extractImage(clientListDollLogo),
      tabletImg: extractImage(clientListDollTablet),
    },
  ]

  return (
    <LayoutInnerPage hasWhiteTransparentNav wrapperClassName={cx.wrapper}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        breadcrumbs={page.breadcrumbs}
        bg={extractImage(bannerBG)}
        img={{
          src: extractImage(bannerImg),
          width: bannerData.img.width,
          height: bannerData.img.height,
          alt: bannerData.img.alt,
        }}
        videoUrl={bannerData.videoUrl}
        heading={bannerData.heading.text}
        subheading={bannerData.subheading.text}
      />
      <ClientList
        bg={extractImage(clientListBG)}
        accent={extractImage(clientListAccent)}
        heading={clientListData.heading.text}
        subheading={clientListData.subheading.text}
        item={clientItemData}
      />
      <Closing
        bg={extractImage(bannerBG)}
        modelImg={extractImage(closingModel)}
        logoImg={extractImage(closingLogo)}
        laptopImg={extractImage(closingLaptop)}
        phoneImg={extractImage(closingPhone)}
        heading={closingData.heading.text}
        testimonial={closingData.testimonial.text}
        client={closingData.client.text}
        domain={closingData.domain.text}
        buttonText={closingData.button.text}
        buttonLink={closingData.button.link}
        alt={closingData.alt}
      />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.modelOrBackground}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.default}
      />
    </LayoutInnerPage>
  )
}

export default VideoTestimonials
