import React from 'react'

const SvgQuotation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="86"
      height="64"
      viewBox="0 0 86 64"
      fill="none"
    >
      <rect width="86" height="64" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_6_105764"
            transform="translate(0 -0.00294643) scale(0.00571429 0.00767857)"
          />
        </pattern>
        <image
          id="image0_6_105764"
          width="175"
          height="131"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK8AAACDCAYAAAD7/Es4AAAACXBIWXMAAC4jAAAuIwF4pT92AAAEuUlEQVR4nO3dyXIVZQCG4deILqwypVXelOPCnXeg4ojiPFQxhUGECAH0BrwJbwAwYMBgQCQ4DysvAKtc/DlVFBJJ53Sfv7/u91mxCH2+xVt/dbpTyX3r6+s3gEcYty+BnbVHNLAEPFd7RGV/7wAeAx6uvaSy+doDGpoHHq09orIdc8Ct2it64J/aAxpK29uFW3O1F0jbZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbyKZbzFg7UHqLEHjLdYqz1Ajd00XvgCWKg9Qo1cB57dUXtFZZ8Dz9ceoUa+B54Aro/55DXcPNfYCBfG+w3bGQw3zTXgSTbChXHGewZ4ofYINfKfcGF88RpunqvcJVwYV7ynMdw0m4YL44n3NPBi7RFqZBLuD5t9wRjiPYXhprlKeaqwabgw/HhPATtrj1Aja5Rwb9zrC4ccr+Hmmdwq3DNcGG68hptncquwpXBhmPEuYbhprgKP0yBcGF68S8BLtUeokTVKuOtN/+OQ4j2J4aaZfHPWOFwYTrwngZdrj1Aj3zFFuDCMeE9guGmuMGW4kB/vCeCV2iPUyBXK47Cb014oOd7PMNw0rYULufGeAF6tPUKNrNJiuJAZrydunlXgKVoMF/LiXcQTN03rJ+5EUryLwGu1R6iRSbg/dnHxlHgNN0+n4UJGvMcx3DSrlOe4nYUL/Y93EXi99gg1Mgn3p64/qM/xHscTN80q5YdsOg8X+hvvMTxx03xLCffnWX1gH+M9BuyqPUKNXKbcKswsXOhfvIab5zLlqcJMw4V+xfsphpvmEpXChf7EexR4o/YINXKJ8sq3SrjQj3iPAm/WHqFGVqgcLkDt389ruHkm4f5Se0jNk9dw86wAT9ODcKFevJ9guGl6catwuxrxHgF2V/hcbd8K5alCL07ciVnHewR4a8afqelMwv219pA7zTJew83T23Bhdk8bDDfPCuWV72+1h2xmFiev4ebpfbjQfbyHMdw03xAQLnQb72Hg7Q6vr/ZdpNzj9j5c6C7eQxhumouU57gR4UI38R4C3unguurOBcLChfbjPYjhprlAeeUbFS60G+9B4N0Wr6fuLRMaLrQX7wKGmyY6XGgn3gXgvRauo9mZhPt77SHTmDbeAxhumkGEC9O9Hj4AvN/WEM3EMuWpwh+1h7Rhuyev4eYZVLiwvZPXcPMsU96c/Vl7SJuanrz7Mdw0gwwXmsW7H/igqyHqxNcMNFzYerz7MNw05yn3uIMMF7YW7z7gw66HqFXnKY/DBhsu3DvevRhumnOMIFz4/3j3Ah/NaohacQ54hhGEC5vHa7h5RhUu3D3ePRhumrOM5FbhdnfGuwf4uMYQbdtZyon7V+0hszYH3L/xb8PNM9pwocT7EOWVr+HmmKf8DYjRhgsl3t34yjfNV4w8XIB/Abap0Dwt+pdsAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  )
}

export default SvgQuotation
