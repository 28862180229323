import { useStaticQuery, graphql } from 'gatsby'

const useVideoTestimonialsMetadata = () => {
  const query = useStaticQuery(graphql`
    query VideoTestimonialsMetadata {
      bannerBG: file(relativePath: { eq: "video-testimonials/banner/bg.jpg" }) {
        id
        ...VideoTestimonialsImageOptimize
      }
      bannerImg: file(
        relativePath: { eq: "video-testimonials/banner/img.jpg" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }

      clientListBG: file(
        relativePath: { eq: "video-testimonials/clientList/bg.jpg" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListAccent: file(
        relativePath: { eq: "video-testimonials/clientList/accent.jpg" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListChooClient: file(
        relativePath: { eq: "video-testimonials/clientList/choo-client.jpg" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListChooLogo: file(
        relativePath: { eq: "video-testimonials/clientList/choo-logo.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListChooTablet: file(
        relativePath: { eq: "video-testimonials/clientList/choo-tablet.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListPrattClient: file(
        relativePath: { eq: "video-testimonials/clientList/pratt-client.jpg" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListPrattLogo: file(
        relativePath: { eq: "video-testimonials/clientList/pratt-logo.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListPrattTablet: file(
        relativePath: { eq: "video-testimonials/clientList/pratt-tablet.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListNarteyClient: file(
        relativePath: { eq: "video-testimonials/clientList/nartey-client.jpg" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListNarteyLogo: file(
        relativePath: { eq: "video-testimonials/clientList/nartey-logo.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListNarteyTablet: file(
        relativePath: { eq: "video-testimonials/clientList/nartey-tablet.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListJacobsonClient: file(
        relativePath: {
          eq: "video-testimonials/clientList/jacobson-client.jpg"
        }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListJacobsonLogo: file(
        relativePath: { eq: "video-testimonials/clientList/jacobson-logo.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListJacobsonTablet: file(
        relativePath: {
          eq: "video-testimonials/clientList/jacobson-tablet.png"
        }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListHabibiClient: file(
        relativePath: { eq: "video-testimonials/clientList/habibi-client.jpg" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListHabibiLogo: file(
        relativePath: { eq: "video-testimonials/clientList/habibi-logo.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListHabibiTablet: file(
        relativePath: { eq: "video-testimonials/clientList/habibi-tablet.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListTutorClient: file(
        relativePath: { eq: "video-testimonials/clientList/tutor-client.jpg" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListTutorLogo: file(
        relativePath: { eq: "video-testimonials/clientList/tutor-logo.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListTutorTablet: file(
        relativePath: { eq: "video-testimonials/clientList/tutor-tablet.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListGilletteClient: file(
        relativePath: {
          eq: "video-testimonials/clientList/gillette-client.jpg"
        }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListGilletteLogo: file(
        relativePath: { eq: "video-testimonials/clientList/gillette-logo.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListGilletteTablet: file(
        relativePath: {
          eq: "video-testimonials/clientList/gillette-tablet.png"
        }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListDollClient: file(
        relativePath: { eq: "video-testimonials/clientList/doll-client.jpg" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListDollLogo: file(
        relativePath: { eq: "video-testimonials/clientList/doll-logo.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      clientListDollTablet: file(
        relativePath: { eq: "video-testimonials/clientList/doll-tablet.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }

      closingModel: file(
        relativePath: { eq: "video-testimonials/closing/model.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      closingLogo: file(
        relativePath: { eq: "video-testimonials/closing/logo.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      closingLaptop: file(
        relativePath: { eq: "video-testimonials/closing/laptop.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
      closingPhone: file(
        relativePath: { eq: "video-testimonials/closing/phone.png" }
      ) {
        id
        ...VideoTestimonialsImageOptimize
      }
    }

    fragment VideoTestimonialsImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useVideoTestimonialsMetadata
