import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import ElementImage from '@components/global/element/Image'
import PlayButton from '@components/global/buttons/PlayButton'
import ModalVideo from '@components/global/modal/Video'
import * as cx from './Banner.module.scss'

const Banner = ({
  wrapperClassName,
  breadcrumbs,
  bg,
  img,
  videoUrl,
  heading,
  subheading,
  headingClassName = 'heading-5',
  subheadingClassName = 'subtitle-1',
}) => {
  const modalVideoRef = useRef()

  return (
    <section
      className={clsx(wrapperClassName, [cx.wrapper])}
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <ModalVideo childFunc={modalVideoRef} url={videoUrl} />
      <div className={cx.container}>
        <Breadcrumbs
          wrapperClassName={`${cx.breadcrumbs} global-breadcrumbs`}
          data={breadcrumbs}
        />
        <h2 className={cx.heading}>
          <span className={headingClassName}>{heading}</span>
          <span className={subheadingClassName}>{subheading}</span>
        </h2>
        <div className={cx.img}>
          <div className={cx.imgPlaceholder} />
          <ElementImage
            src={img.src}
            width={img.width}
            height={img.height}
            alt={img.alt}
            lazy={false}
          />
          <PlayButton onClick={() => modalVideoRef.current.openModal()} />
        </div>
      </div>
    </section>
  )
}

Banner.propTypes = {
  wrapperClassName: PropTypes.string,
  breadcrumbs: PropTypes.array,
  bg: PropTypes.string,
  img: PropTypes.object,
  videoUrl: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
}

export default Banner
