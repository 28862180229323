import React from 'react'

export const pageData = {
  title: 'Video Testimonials',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    { text: 'Client Testimonials', url: '/client-testimonials/' },
    {
      text: 'Video Testimonials',
      url: '/client-testimonials/video-testimonials/',
    },
  ],
  uri: '/client-testimonials/video-testimonials/',
  lcf: {
    version: 'LCF08',
    modelOrBackground: 'CutOutHarvey',
    title: 'Want World-Class Website Design & Customer Service?',
    subtitle: 'Chat with our web marketing strategists to get started',
    submitLabel: 'Schedule a consultation',
    leadName: 'Lead Contact Form',
    layout: 'default',
  },
}

export const bannerData = {
  imgAlt: 'The Caroll Group laptop',
  heading: {
    text: 'Our Clients Have Spoken',
    className: 'heading-5',
  },
  subheading: {
    text: 'Learn why elite realtors trust Agent Image with their websites.',
    className: 'subtitle-1',
  },
  img: {
    width: 814,
    height: 460,
    alt: 'Why Agent Image?',
  },
  videoUrl: 'https://www.youtube.com/watch?v=bLyLfN_m_1s&ab_channel=AgentImage',
}

export const clientListData = {
  heading: {
    text: 'Video Testimonials',
    className: 'heading-2',
  },

  subheading: {
    text: "We'll let our clients do the talking.",
    className: 'subtitle-1',
  },
  clientList: {
    testimonial1ClassName: 'subtitle-9',
    testimonial2ClassName: 'subtitle-5',
    domainClassName: 'menu-link',
    clientItem: [
      {
        client: 'Christophe Choo',
        domain: 'christophechoo.com',
        testimonials: [
          "They're reimagining, redesigning and rebuilding my site to today's modern world which is what you want.",
          'The team is amazing, the design is amazing.',
        ],
        link:
          'https://www.youtube.com/watch?v=iLbG8sjld-8&ab_channel=AgentImage',
      },
      {
        client: 'Brandi Pratt',
        domain: 'theprattgroup.com',
        testimonials: [
          'Love Agent Image! Super happy with my website, always receive quick and outstanding support.',
          'Highly recommend them!',
        ],
        link:
          'https://www.youtube.com/watch?v=a2lodtrN-cg&ab_channel=AgentImage',
      },
      {
        client: 'Kofi Nartey',
        domain: 'thenarteygroup.com',
        testimonials: [
          'Agent Image has helped us capture our brand in an impressive and engaging way.',
          'They are industry icons when it comes to personal and company websites especially in the Real Estate space.',
        ],
        link:
          'https://www.youtube.com/watch?time_continue=3&v=TakEnESS1RQ&feature=emb_title',
      },
      {
        client: 'Georgina Jacobson',
        domain: 'georginajacobson.com',
        testimonials: [
          "It's not only been an amazing experience but I feel it really sets me apart from my competitors.",
          'I love my website.',
        ],
        link:
          'https://www.youtube.com/watch?v=ktBumzSObn0&ab_channel=AgentImage',
      },
      {
        client: 'Roh Habibi',
        domain: 'rohhabibi.com',
        testimonials: [
          'When I go onto my website, I literally get this feeling in my stomach.',
          'It is the best website for a real estate agent in the game.',
        ],
        link:
          'https://www.youtube.com/watch?v=MKj3hWUpsRk&ab_channel=AgentImage',
      },
      {
        client: 'Tracy Tutor',
        domain: 'TracyTutor.com',
        testimonials: [
          'They were absolute pros. They understood what I wanted and were able to execute in a timely fashion.',
          'Agent Image understands every need that you possibly have. Thank you so much.',
        ],
        link:
          'https://www.youtube.com/watch?v=NXAasr2sgUI&ab_channel=AgentImage',
      },
      {
        client: 'Shannon Gillette',
        domain: 'gillettegroupaz.com',
        testimonials: [
          'I’ve had it for a few years now and it’s honestly made my business flow to the next level.',
          'I don’t even have to do a traditional listing presentation because my website has everything.',
        ],
        link:
          'https://drive.google.com/file/d/1Qgp0PGtgY9yOb0GD_VTGBCunMd_tU0cy/view ',
      },
      {
        client: 'Dawn Doll',
        domain: 'destinationhometn.com',
        testimonials: [
          'When you have these guys at Agent Image, It makes the process a whole lot smoother.',
          'Building your brand, and building your company. It’s a great name, Agent Image. Because it is about building your brand.',
        ],
        link:
          'https://drive.google.com/file/d/14cloDcbTnmqUSlVNwgms2aAFli7eXoTp/view',
      },
    ],
  },
}

export const closingData = {
  heading: {
    text: '6,500 FIVE-STAR REVIEWS AND COUNTING!',
    className: 'subtitle-1',
  },
  testimonial: {
    text:
      '“Love Agent Image!<br/> Super happy with my website, always receive quick and outstanding support. Highly recommend them!”',
    className: 'subtitle-9',
  },
  client: { text: 'Brandi Pratt', className: 'subtitle-3' },
  domain: { text: 'theprattgroup.com', className: 'menu-link' },
  button: {
    text: 'More Success Stories',
    link: '/our-work/client-testimonials/',
  },

  alt: 'The Pratt Group',
}
