import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import PlayButton from '@components/global/buttons/PlayButton'
import ModalVideo from '@components/global/modal/Video'
import SvgQuotation from '@svg/quotation.inline.js'
import { useIntersectOnMobile } from '@hooks/useIntersect'
import * as cx from './ClientList.module.scss'

const ClientItem = ({
  bg,
  accent,
  client,
  domain,
  testimonial1,
  testimonial2,
  link,
  clientImg,
  logoImg,
  tabletImg,
  testimonial1ClassName = 'subtitle-9',
  testimonial2ClassName = 'subtitle-5',
  domainClassName = 'menu-link',
}) => {
  const modalVideoRef = useRef()
  const [cardRef, isCardIntersecting, isMobile] = useIntersectOnMobile()

  return (
    <li className={cx.itemWrapper}>
      <ModalVideo childFunc={modalVideoRef} url={link} />

      <ElementImage className={cx.bg} src={bg} alt="testimonial background" />
      <div className={cx.item}>
        <div className={cx.imgContainer}>
          <ElementImage
            className={cx.accent}
            src={accent}
            alt="navy gradient"
          />
          <div className={cx.imgInner}>
            <div className={cx.imgPlaceholder} />
            <ElementImage src={clientImg} alt={client} />
            <ElementImage
              className={cx.logoImg}
              src={logoImg}
              alt={`${client} logo`}
            />
            <ElementImage
              className={cx.tabletImg}
              src={tabletImg}
              alt={`${client} website screenshot`}
            />
            <PlayButton
              wrapperClassName={cx.button}
              onClick={() => modalVideoRef.current.openModal()}
            />
          </div>
        </div>

        <div
          ref={cardRef}
          className={clsx([cx.content], {
            'tempo-hidden': isMobile && !isCardIntersecting,
            [cx.cardIntersect]: isMobile && isCardIntersecting,
          })}
        >
          <SvgQuotation />
          <p className={testimonial1ClassName}>{testimonial1}</p>
          <p className={testimonial2ClassName}>{testimonial2}</p>

          <span className={cx.client}>{client}</span>
          <span className={domainClassName}>{domain}</span>
        </div>
      </div>
    </li>
  )
}

const ClientList = ({
  wrapperClassName,
  bg,
  accent,
  heading,
  subheading,
  headingClassName = 'heading-2',
  subheadingClassName = 'subtitle-1',
  item: clientItem,
}) => {
  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <h2 className={cx.header}>
        <span className={headingClassName}>{heading}</span>
        <span className={subheadingClassName}>{subheading}</span>
      </h2>
      <ul className={cx.list}>
        {clientItem?.map((item, key) => {
          return (
            <ClientItem
              key={key}
              bg={bg}
              accent={accent}
              client={item?.client}
              domain={item?.domain}
              testimonial1={item?.testimonial1}
              testimonial2={item?.testimonial2}
              link={item?.link}
              clientImg={item?.clientImg}
              logoImg={item?.logoImg}
              tabletImg={item?.tabletImg}
            />
          )
        })}
      </ul>
    </section>
  )
}

ClientItem.propTypes = {
  bg: PropTypes.string,
  accent: PropTypes.string,
  client: PropTypes.string,
  domain: PropTypes.string,
  testimonial1: PropTypes.string,
  testimonial2: PropTypes.string,
  link: PropTypes.string,
  clientImg: PropTypes.string,
  logoImg: PropTypes.string,
  tabletImg: PropTypes.string,
  testimonial1ClassName: PropTypes.string,
  testimonial2ClassName: PropTypes.string,
  domainClassName: PropTypes.string,
}

ClientList.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  accent: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  item: PropTypes.array,
}

export default ClientList
